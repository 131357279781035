<template>
  <div  class="main-container">  
    <img :src="require('../assets/wallet-bg.jpg')" class="bg">

     <div id="loading" v-if="showLoading">
      <div class="content">
 
        <v-icon name="spinner" pulse scale="3" />

      </div>
    </div>


    <div class="header"> 
      <div class="d-flex" style="width:50%; cursor: pointer;" @click="goToDefault"> 
        <div class="mr-3"><v-icon name="chevron-left" class="chevron-left" /></div>
        <h5>BACK</h5>
      </div>
      <div>
        <a href="#" class="logout" v-on:click="logout">Logout</a>
      </div>
    </div>

    <div class="logo">
       <img :src="require('../assets/logo.png')" >
    </div>
 

    <div class="transaction-content">
          <div class="d-flex justify-content-between">
            <div></div>
            <b-dropdown id="dropdown-1" text="Choose Asset" class="m-md-2">
              <div v-for="info in subwalletInfo" :key="info.id">
                <b-dropdown-item v-if="info.symbol != 'CXT'" @click="getFund(info.symbol)">{{ info.symbol }}</b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
          <div class="history-header"> 
            <span> HISTORY </span>
          </div>
          
          <div class="history-content">
            <table>
              <tr>
                <th>TIMESTAMP</th>
                <th>HASH</th>
                <th>INPUT</th>
                <th>OUTPUT</th>
                <th>AMOUNT</th> 
                <th>CONFIRMATIONS</th> 
                <th>STATUS</th>
                <th>BALANCE</th>
              </tr>
              <tr v-for="h in selectedHistory" :key="h.hash" @click="initView(h)">
                <td >{{ formatDate(h.input.timeStamp) }}</td>
                <!-- <td class="th-orange"> <div class="hash"> <a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + h.hash" target="_blank">{{ h.hash }}</a> </div> </td> -->
                <td class="th-orange"> <div class="hash"> <a :href="'https://test-explorer-cxt.coexstar.com/#/transaction/' + h.hash" target="_blank">{{ h.hash }}</a> </div> </td>
                <td class="th-orange"> {{ h.senderWallet != null ? h.senderWallet.publicKey : "CXT Genesis Asset" }}  </td>
                <td class="th-orange">{{ h.recipient }}</td>
                <td> {{ h.symbol }} {{ formatAmount(h.amount) }} </td> 
                 <td class="th-orange">{{ h.confirmations }}</td>
                 <td v-if="h.confirmations >= 2">COMPLETED</td>
                 <td v-else>PENDING</td>
                 <td> {{ h.symbol }} {{ selectedBalance.toFixed(8) }} </td>
              </tr>
            
            </table>
        
          </div> 
    </div>
 
  </div>
</template>

<script>

  import qs from 'querystring'
  import numeral from 'numeral'  

  export default {
    name: 'history',
    components: {
    },
    data: function() {
      return  {
        showLoading: true, 
        status: "",
        subwalletInfo: [],
        selectedHistory: [],
        selectedBalance: 0
      }
    },
    computed: {
      init() {
          return this.$store.state.init;
      },
    },
    methods: {
      goToDefault() {
        this.$router.push('/')
      },

      initView(content) {
        console.log(content)
      },

      formatDate: function(time){
        return new Date(time).toLocaleString();
      },
      formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },
      getAddress: function(){
         const options = {
            method: 'POST',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/address/CXT',
          };

          this.axios(options)
            .then((response) => {
                this.init.address = response.data;
                //console.log(this.init.address)
                this.getSubwalletInfo();
              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },

      getSubwalletInfo() {
          const options = {
                method: 'GET',
                url: '/pub/wallets/' + this.init.address.addressValue,
                };
                this.axios(options)
                    .then((response) => {
                      console.log(response.data)
                          console.log('yes')
                          this.subwalletInfo = response.data
                          console.log(this.subwalletInfo)
                    })
                    .catch((error) => {
                      console.log(error)
                      // if(response.assetKey != "" && response.assetName == "") {
                      //   this.showSteps.one = false
                      //   this.showCheck.one = true
                      //   this.showSteps.two = false
                      //   this.showSteps.three = true
                      //   this.showCheck.two = true
                      //   this.isDisabled.three = false
                      // }
                    });
          },

      getFund: function(symbol){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cxt-api/address/' + symbol + '/' + this.init.address.addressValue,
          };

          this.axios(options)
            .then((response) => {
               this.selectedBalance = response.data.balance
               this.selectedHistory = response.data.history;

              // console.log(this.init.fund.balance);

              this.selectedHistory.sort(function(a, b) {

                return parseFloat(b.input.timeStamp) - parseFloat(a.input.timeStamp);
              });

              //this.init.fund.history = this.init.fund.history.slice(0, 10);

              }).catch((err) => {
                  
                console.log(err.response.data)     

                
              })
      },
      getProfile: function(){
         const options = {
            method: 'GET',
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            url: '/cx/profile/',
          };

          this.axios(options)
            .then((response) => {
               
               this.mintedHistory = response.data.history;

              console.log(this.init.profile);


                
              })
      },
      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => { 
            this.getProfile();
              setTimeout( () => {
                this.showLoading = false;
              },1000)
            }).catch((err) => {
                
              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  

              if(err.response.data.msgCode == '00020') {

                setTimeout( () => {
                window.location.href = "/#/setup-profile";
               },1000)
                
              }  

            })
      },

      logout: function(){
            this.$bvModal.show('logout-modal'); 
      },
    },

    beforeMount(){
     this.checkSession();
      this.getAddress();
    },

    watch: {

    }
  }
</script>

<style scoped lang="scss">
  #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #FFF;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #141414;
}
#loading .content {
  display: inline-block;
  text-align: center;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
}

.fa-icon { 
    color: #FFF;
    fill: #fff;
}
  .z-index-2 {
    z-index: 2;
  }
  .transaction-content {
    padding: 0 14em;
    margin-top: 3em;

  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #333;
  }
   .hash {
    white-space: nowrap !important;
    width: 20em;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  td, th {
   
    text-align: left;
    padding: 1em;
    font-size: 0.75em;
  }

  td {  
    background: #2D2D2D;
    color: #FFF;
    border-bottom: 1px solid #FFF;
    padding: 1.5em;
  
  }

  th {
     
    background: #606060;
    color: #FFF;
  }

  th:first-child { 
    border-top-left-radius: 6px;
  }
  th:last-child { 
    border-top-right-radius: 6px;
  }

  .history-content {
    background: #FFF; 
      border-radius: 6px;
        overflow-x:auto;
  }
    


  .history-container {  
      padding: 1.5em;
      border-radius: 6px;
      flex-direction: column;
      position: relative;
      background: #FFF;
      padding-top: 5em;
      color: #000;
      background-color: #b48b41;
      background-image: linear-gradient(#b48b41, #b48b41);
      width: 100%;
      margin: 0 2em;
  }
  
  .history-header {  
    color: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;;

    a {
      color: #FFF;
      text-decoration: underline;
    }
  }
.logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    margin-top: 2em;
    img{
      width: 75px;  
    }
    
  }


  .option-box {

    background: #141414;
    border: 1px solid #8c5b13;
    color: #FFF;
    border-radius: 6px; 
    min-height: 15em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 3em;
    cursor: pointer;

    h6 {
      font-size: 0.75em;
    }
  }
 .content-icon {
      width: 75px;
    }
  .balance {
    font-size: 0.8em;
    position: relative;
    width: 30%; 
    background-image: url(../assets/wallet-bg.jpg);
    box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-webkit-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
-moz-box-shadow: -39px -40px 0px 0px rgba(222,132,19,0.75);
    padding: 1em;
    color: #FFF;
    border-radius: 6px;
    min-height: 11em;
    display: flex;
    flex-direction: column;
    justify-content: center;
        background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .bal-bg {
          position: absolute;
        z-index: 1;
        width: 20em;
        left: 2em;
        top: 2em;
    }
  }

  .header { 
    
    background: #141414;
    padding: 2em;
    display: flex;
    align-items: end;
    justify-content: space-between;
    color: #FFF;
  }
  .logout {
    color: #FFF;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 1em;
    padding: 0 15em;
  }

  .btn-secondary {
      background:none !important;
      border-color:orange !important;
      color:white !important;
      border-width:2px !important;
      margin-left:5px !important;
    }

  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
 
  }
  /*med*/
  @media(max-width: 63.9375em) {

    
    
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(min-width: 768px) {
    .content {
      padding: 0 3em;
    }

    .transaction-content {
      padding: 3em;
    }

  }

</style>



